iframe {
    pointer-events: none;
}

.container{
    padding-left: 200px!important;
    padding-right: 200px!important;
}

.bg-blue-dark{
    background: #1866af;
}

.cl-blue-dark{
    color: #1866af;
}

.bg-red{
    background: white;
}

.bg-grey{
    background: #84a0b8;
}

.bg-grey-dark{
    background: #f6f6f6;
}

.cl-white{
    color: white!important;
}

.cl-black{
    color: black!important;
}

.cl-red{
    color: red!important;
}

.fw-bold{
    font-weight: bold;
}

.bg-green-whatsapp{
    background: #00d750;
}

.as-center{
    align-self: center;
}

.w-18px{
    width: 18px;
}

.h-18px{
    height: 18px;
}

.w-24px{
    width: 24px;
}

.w-48px{
    width: 48px;
}

.h-48px{
    height: 48px;
}

.h-24px{
    height: 24px;
}

.w-18{
    width: 18%;
}

.h-18{
    height: 18%;
}

.w-20{
    width: 20%;
}

.w-25{
    width: 25%;
}

.w-30{
    width: 30%;
}

.w-60{
    width: 60%;
}

.w-50{
    width: 50%;
}

.w-55{
    width: 55%;
}

.w-75{
    width: 75%;
}

.w-100{
    width: 100%;
}

.cursor-pointer{
    cursor: pointer;
}

.fs-12{
    font-size: 12px;
}

.fs-14{
    font-size: 14px;
}

.fs-16{
    font-size: 16px;
}

.fs-20{
    font-size: 20px;
}

.fs-28{
    font-size: 28px;
}

.paddinge-24{
    padding-right: 24px;
}

.top-72px{
    top: 72px;
}

.bottom-72px{
    bottom: 72px;
}

.start-20{
    left: 20%;
}

.rounded-16{
    border-radius: 16px;
}

.rounded-8{
    border-radius: 8px;
}


.rounded-4{
    border-radius: 4px;
}

.text-align-center{
    text-align: center;
}

.opacity-8{
    opacity: 0.8;
}

.form-control:focus,
.navbar-toggler:focus{
    box-shadow: none;
}

.text-decoration-none{
    text-decoration: none;
}

.list-style-none{
    list-style: none;
}

input[type=number] { -moz-appearance:textfield; }
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

#main-home{
    margin-top: 89px;
}

#tradicion{
    border-bottom: 2px solid #ebebeb;
}

@media (max-width: 1350px){/**1200px - 1350px**/
    .container{
        padding-left: 150px!important;
        padding-right: 150px!important;
    }
}

@media (max-width: 1199px){/**992px -1199px**/
    .container{
        padding-left: 75px!important;
        padding-right: 75px!important;
    }    

    #main-home #formulario.start-20{
        left: 10%!important;
    }

    #main-home #formulario.w-30{
        width: 40%!important;
    }
    
}

@media (max-width: 991px){/**768 - 991px**/
    .container{
        padding-left: 25px!important;
        padding-right: 25px!important;
    }

    #barramenu .collapse .d-flex{
        display: block!important;
    }
    
    #barramenu .collapse .d-flex .w-80,
    #barramenu .collapse .d-flex .w-20{
        width: 100%!important;
    }
    
    #barramenu .collapse .d-flex .w-25{
        display: none!important;
    }

    #end .d-flex{
        display: block!important;
    }
    
    #end .d-flex .w-20,
    #end .d-flex .w-55{
        width: 100%!important;
        margin-bottom: 8px;
    }

    #end .d-flex .w-55 p{
        text-align: left!important;
    }

    #end .d-flex .w-20 img{
        width: 60%;
    }

    #end button{
        display: none!important;
    }

    #end .d-flex .w-55 .d-flex{
        display: flex!important;
    }

    #end .d-flex .w-25{
        display: none!important;
    }
}

@media (max-width: 767px){ /**576px - 767px**/   
    #barrainfo{
        display: none;
    }

    #main-home{
        margin-top: 56px!important;
    }

    #main-home #formulario.start-20{
        left: 15%!important;
    }

    #main-home #formulario.w-30{
        width: 50%!important;
    }
    
    #lacarta .d-flex.bg-white{
        width: 100%!important;
    }
}

@media (max-width: 575px) { /**0 - 575px**/

    #main-home #formulario.w-30{
        width: 70%!important;
    }
    
    #carousel #formulario{
        height: 80%;
    }
}

@media (max-width: 400px){

    #main-home #formulario.w-30{
        width: 70%!important;
    }
}